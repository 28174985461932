<template>
  <!-- <div id="main"></div> -->
  <!-- <LocaleChanger/> -->
  <!-- <form-button/> -->
  <router-view/>
  <toast-notification
    ref="toastNotification"
    :showToast="showToast"
    :toastMessage="toastMessage"
    :toastType="toastType"
    @update:showToast="showToast = $event"
  />

</template>

<script>
// import LocaleChanger from "./vues/elements/LocaleChanger.vue";
// import formButton from "./vues/elements/formButton.vue";
import toastNotification from "./vues/elements/toastNotification.vue";

export default {
  // components: { LocaleChanger },
  // components: { formButton },
  components: { toastNotification },
  data() {
    return {
      showToast: false,
      toastMessage: "",
      toastType: "success",
    };
  },
};
</script>

<style>
/*POLICE UTILISEE DANS L'APP*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
/* font-family: 'Roboto', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhaina+2:wght@400;500;600;700;800&display=swap');
/* font-family: 'Baloo Bhaina 2', cursive; */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* font-family: 'Montserrat', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* font-family: 'Rubik', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
/* font-family: 'Roboto Mono', monospace; */
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* front-family:Jost */

/*Scroll en mode smooth*/
html{
  scroll-behavior: smooth;
}
#main{
  /* position: relative; */
}

#app {
  font-family: Jost, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
  /* margin: 3%; */
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction:row;
}

nav{
  display: flex;
  flex-direction: row;
  align-items: center;
}

#titre{
  display: flex;
  flex-direction: row;
  text-decoration: none;
  justify-content: flex-start;
  align-items: center;
  color: #2c3e50;

}

.nav-link{
  font-weight: bold;
  font-size: large;
  text-decoration: none;
  display: inline-block;
  font-size: xx-large;
  margin: 3%;
  color: #333399;
}

</style>
