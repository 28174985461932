<template>
  <transition name="fade">
    <div v-if="showToast" :class="`toast ${toastType}`" @click="close">
      {{ toastMessage }}
    </div>
  </transition>
</template>

<script>
import eventBus from '../../eventBus';

export default {
  name: 'ToastNotification',
  data() {
    return {
      showToast: false,
      toastMessage: '',
      toastType: 'success'
    };
  },
  created() {
    eventBus.on('show-toast', this.showToastMessage);
  },
  methods: {
    showToastMessage({ message, type, duration = 3000}) {
      this.toastMessage = message;
      this.toastType = type;
      this.showToast = true;

      setTimeout(() => {
        this.showToast = false;
      }, duration);
    },
    close() {
      this.showToast = false;
    }
  },
  beforeUnmount() {
    eventBus.off('show-toast', this.showToastMessage);
  }
}
</script>
